<!--
File: WorkCategoriesDropdown.vue
Description: show the dropdown combo with the Work Categories list.
-->
<template>
  <md-field>
    <label for="work_category_id">{{ label }}</label>
    <md-select :required="is_required" v-model='work_category_id' name="work_category_id"
      id="work_category_id" @md-selected='onChange' :disabled="disabled">
      <md-option v-for='(cat, ind) in categoriesList' :key='ind' :value='cat.work_category_id'>
        {{ cat.description }}
      </md-option>
    </md-select>
  </md-field>
</template>
<script>
  export default {
    name: 'work-categories-dropdown',

    props: {
      label: { default: null, type: String },
      initial_value: { default: null, type: Number },
      is_required: { default: false, type: Boolean },
      disabled: { default: false, type: Boolean }
    },

    data() {
      return {
        work_category_id: null
      }
    },

    mounted() {
      this.$store.dispatch('GET_WORK_CATEGORIES_LIST', !this.is_required).then(() => {
        if (this.initial_value && !this.work_category_id) this.work_category_id = this.initial_value
      })
    },

    methods: {
      onChange() {
        this.$emit('input', this.work_category_id)
      },
    },

    computed: {
      categoriesList() {
        return this.$store.state.Dropdowns.work_categories
      }
    }
  }
</script>